import Progress from '../../../components/external/Progress'
import GlobalMixin from '../../mixin/index'
export default {
  mixins:[GlobalMixin],
  data () {
    return {
      user_edit: null,
      dialog_delete:false,
      dialog_detail:false,
      data_detail:{},
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      from_menu:false,
      to_menu:false,
      branch_id:'',
      branch:'',
    }
  },
  components: {
    Progress,
  },
  methods: {
    openDetail: function (item = {}) {
      this.data_detail = item
      this.dialog_detail = !this.dialog_detail
    },
    async go (table,store) {
      await this.axios.get('/v1/'+ table +'?company_id=' + this.user.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
    close () {
      this.user_edit = null
      this.dialog_delete = false
      this.dialog_form = false
    },
  },
  watch: {
    from_menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
}
